import { Link, graphql } from "gatsby"
import React, { useEffect, forwardRef } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

export const Header = forwardRef((props, ref: any) => {
  return (
    <header
      ref={ref}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "#b81430",
      }}
    >
      <Row>
        <HeaderItem>
          <Link to="/">Home</Link>
        </HeaderItem>
        <HeaderItem>
          <Link to="/about-us">About Us</Link>
        </HeaderItem>
        <HeaderItem>
          <Link to="/carriers">Carriers</Link>
        </HeaderItem>
        <HeaderItem>
          <Link to="/shippers">Shippers</Link>
        </HeaderItem>
        <HeaderItem>
          <Link to="/contact">Contact Us</Link>
        </HeaderItem>
      </Row>
    </header>
  )
})

interface MobileHeaderProps {
  toggleOpen: () => void
}

export const MobileHeader = forwardRef(
  ({ toggleOpen }: MobileHeaderProps, ref: any) => {
    return (
      <header
        ref={ref}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "10px 20px",
          backgroundColor: "#b81430",
        }}
      >
        <a onClick={toggleOpen}>
          <FontAwesomeIcon icon={faBars} color="white" size="2x" />
        </a>
      </header>
    )
  }
)
const AnimatedHeader = styled.header`
  background: linear-gradient(122deg, #000098, #000, #b81430, #000);
  background-size: 600% 600%;

  -webkit-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0% 97%;
    }
    50% {
      background-position: 100% 4%;
    }
    100% {
      background-position: 0% 97%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 0% 97%;
    }
    50% {
      background-position: 100% 4%;
    }
    100% {
      background-position: 0% 97%;
    }
  }
`
const HeaderItem = styled.div`
  padding: 10px 15px;
  text-align: center;
  :hover {
    a {
      color: #b71430;
    }
    background-color: white;
  }
  a {
    color: white;
    text-decoration: none;
    font-size: calc(10px + 2vh);
    font-weight: bold;
  }
`

const Row = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
`
