import React, { forwardRef } from "react"
import styled from "styled-components"
import facebook from "../images/social/facebook.svg"
import twitter from "../images/social/twitter.svg"
import linkedin from "../images/social/linkedin.svg"
const Footer = forwardRef((props, ref: any) => (
  <AppFooter ref={ref}>
    <h3>Become One of America’s Transportation Providers!</h3>
    <br />
    <div style={{ display: "flex", flexDirection: "row" }}>
      <a title="facebook" href="https://www.facebook.com/Plainsdedicatedllc/">
        <img
          src={facebook}
          alt="Facebook"
          style={{ width: "2em", height: "2em", color: "#fff" }}
        />
      </a>
      <a title="twitter" href="https://twitter.com/PlainsDedicated">
        <img
          className="fas fa-lg"
          src={twitter}
          alt="Twitter"
          style={{ width: "2em", height: "2em" }}
        />
      </a>
      <a
        title="linkedin"
        href="https://www.linkedin.com/company/plains-dedicated-co-op/"
      >
        <img
          className="fas fa-lg"
          src={linkedin}
          alt="linkedin"
          style={{ width: "2em", height: "2em" }}
        />
      </a>
    </div>
  </AppFooter>
))

const AppFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  bottom: 0;
  width: 100%;
  h3 {
    padding: 20px;
    font-size: calc(10px + 1vw);
    margin: 0;
  }
  div {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  a {
    padding-left: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  background-color: #b81430;
  // background: linear-gradient(122deg, #000098, #333, #b81430, #333);
  // background-size: 600% 600%;

  // -webkit-animation: AnimationName 30s ease infinite;
  // animation: AnimationName 30s ease infinite;

  // @-webkit-keyframes AnimationName {
  //   0% {
  //     background-position: 0% 97%;
  //   }
  //   50% {
  //     background-position: 100% 4%;
  //   }
  //   100% {
  //     background-position: 0% 97%;
  //   }
  // }
  // @keyframes AnimationName {
  //   0% {
  //     background-position: 0% 97%;
  //   }
  //   50% {
  //     background-position: 100% 4%;
  //   }
  //   100% {
  //     background-position: 0% 97%;
  //   }
  // }
`

export default Footer
