import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

interface SidebarProps {
  toggleOpen: () => void
}

const Sidebar = ({ toggleOpen }: SidebarProps) => {
  return (
    <Row>
      <HeaderItem>
        <a onClick={toggleOpen}>
          <FontAwesomeIcon icon={faTimes} color="white" size="lg" />
        </a>
      </HeaderItem>
      <HeaderItem>
        <Link to="/">Home</Link>
      </HeaderItem>
      <HeaderItem>
        <Link to="/about-us">About Us</Link>
      </HeaderItem>
      <HeaderItem>
        <Link to="/carriers">Carriers</Link>
      </HeaderItem>
      <HeaderItem>
        <Link to="/shippers">Shippers</Link>
      </HeaderItem>
      <HeaderItem>
        <Link to="/contact">Contact Us</Link>
      </HeaderItem>
    </Row>
  )
}

export default Sidebar

const HeaderItem = styled.div`
  padding: 10px 15px;
  text-align: left;
  a {
    color: white;
    text-decoration: none;
    font-size: calc(10px + 2vh);
    font-weight: bold;
  }
`

const Row = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 200px;
`
